const configEnvs = {
  development: {
    // GRAPHQL_URL: "https://boreqrapp-gqlapi-dev.azurewebsites.net/graphql",
    REDIRECT_URL: "http://localhost:3000/",
    // AZURE_REDIRECT_URL: "https://boreqrapp-ui-dev.azurewebsites.net/",
    // CLIENT_ID: "7d2ed302-cf45-4801-83eb-d125a6caba79",
    // TENANT_ID: "cca76bf2-ad28-4adb-bbd0-deeb9dd15a80",
    // API_KEY: "da2-pjp7smswzjbl5ds3pdptas4qzi",
    // // OWLBOT: "https://boreqrapp-py-dev.azurewebsites.net/api/lambda_owlBot",
    // // OWLBOT: "https://cctp3k66dtcixr3iq2fwbetoea0pxgcy.lambda-url.us-east-2.on.aws",
    GRAPHQL_URL: "https://boreqrapp-gqlapi-prod.azurewebsites.net/graphql",
    // REDIRECT_URL: "https://reqr.blueowl.com",
    AZURE_REDIRECT_URL: "https://boreqrapp-ui-prod.azurewebsites.net/",
    CLIENT_ID: "7f3cf4bc-503c-4d64-8119-86a01659d1a6",
    TENANT_ID: "cca76bf2-ad28-4adb-bbd0-deeb9dd15a80",
    API_KEY: "da2-pjp7smswzjbl5ds3pdptas4qzi",
    // OWLBOT: "https://boreqrapp-py-prod.azurewebsites.net/api/lambda_owlBot",
  },
  dev: {
    GRAPHQL_URL: "https://boreqrapp-gqlapi-dev.azurewebsites.net/graphql",
    REDIRECT_URL: "https://dev-reqr.blueowl.com",
    AZURE_REDIRECT_URL: "https://boreqrapp-ui-dev.azurewebsites.net/",
    CLIENT_ID: "7d2ed302-cf45-4801-83eb-d125a6caba79",
    TENANT_ID: "cca76bf2-ad28-4adb-bbd0-deeb9dd15a80",
    API_KEY: "da2-pjp7smswzjbl5ds3pdptas4qzi",
    // OWLBOT: "https://boreqrapp-py-dev.azurewebsites.net/api/lambda_owlBot",
  },
  uat: {
    GRAPHQL_URL: "https://boreqrapp-gqlapi-uat.azurewebsites.net/graphql",
    REDIRECT_URL: "https://uat-reqr.blueowl.com",
    AZURE_REDIRECT_URL: "https://boreqrapp-ui-uat.azurewebsites.net/",
    CLIENT_ID: "a02e8d04-9b94-4ec1-815e-1054cc0f20f2",
    TENANT_ID: "cca76bf2-ad28-4adb-bbd0-deeb9dd15a80",
    API_KEY: "da2-pjp7smswzjbl5ds3pdptas4qzi",
    // OWLBOT: "https://boreqrapp-py-uat.azurewebsites.net/api/lambda_owlBot",
  },
  prod: {
    GRAPHQL_URL: "https://boreqrapp-gqlapi-prod.azurewebsites.net/graphql",
    REDIRECT_URL: "https://reqr.blueowl.com",
    AZURE_REDIRECT_URL: "https://boreqrapp-ui-prod.azurewebsites.net/",
    CLIENT_ID: "7f3cf4bc-503c-4d64-8119-86a01659d1a6",
    TENANT_ID: "cca76bf2-ad28-4adb-bbd0-deeb9dd15a80",
    API_KEY: "da2-pjp7smswzjbl5ds3pdptas4qzi",
    // OWLBOT: "https://boreqrapp-py-prod.azurewebsites.net/api/lambda_owlBot",
  },
};

let environment = process.env.NODE_ENV;
if (environment === "production") {
  environment = process.env.REACT_APP_ENV;
}

const configEnv = configEnvs[environment];

export default configEnv;
